@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.landing-bg {
  background: url("./assets/landing-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-bg {
  background: url("./assets/login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
